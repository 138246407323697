import React from "react"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring'

import device from "../../styles/device"
import fonts from "../../styles/fonts"

const Title = styled.h1`
  color: white;
  font-family: ${fonts.poppins};
  font-size: 52px;
  line-height: 62px;
  font-weight: bold;
  flex: 1;

  @media ${device.phone} {
    font-size: 40px;
    line-height: 45px;
  }
`

const BinariosHeroTitleS = styled.div`
  width: 55%;
  z-index: 10;

  @media ${device.tablet} {
    width: 80%;
  }

  @media ${device.phone} {
    width: 100%;
  }
`

export default ({ title }) => {

  const props = useSpring({
    from: {
      opacity: 0, 
      transform: 'scale(1.2)'
    },
    to: {
      opacity: 1,
      transform: 'scale(1)'
    }
  })

  const AnimatedBinariosHeroTitleS = animated(BinariosHeroTitleS)
  
  return (
    <AnimatedBinariosHeroTitleS style={props}>
      <Title>{title}</Title>
    </AnimatedBinariosHeroTitleS>
  )
}
