import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const One = () => {

    const oneCircle = useRef()
    const oneRectangle = useRef()
    const oneShadowRight = useRef()
    const oneShadowLeft = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
        tl.to(oneCircle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"})
        .to(oneRectangle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
        .to(oneShadowLeft.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
        .to(oneShadowRight.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
        .to(oneCircle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut})
        .to(oneRectangle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
        .to(oneShadowLeft.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
        .to(oneShadowRight.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6");
    }, [] )

    return (
        <div>
            <svg width="70px" height="95px">
                <g stroke="none" strokeWidth="1" fill="none">
                    <g transform="translate(-126.000000, -181.000000)">
                        <g transform="translate(127.000000, 182.000000)">
                            <circle ref={oneCircle} stroke="#FC4C5D" strokeWidth="2" strokeLinejoin="round" cx="11.5" cy="11.5" r="11.5"></circle>
                            <rect ref={oneRectangle} stroke="#2214FE" strokeWidth="2" x="19" y="1" width="18" height="72" rx="3"></rect>
                            <ellipse ref={oneShadowLeft} fill="#E4E1FF" cx="29" cy="88.5" rx="23" ry="2.5"></ellipse>
                            <ellipse ref={oneShadowRight} fill="#E4E1FF" cx="29" cy="88.5" rx="23" ry="2.5"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default One