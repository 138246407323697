import React, {useState, useEffect} from 'react'
import styled from "styled-components"
import { useTrail, useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device, {deviceSize} from "../../styles/device"

import One from "./animations/one"
import Two from "./animations/two"
import Three from "./animations/three"
import Four from "./animations/four"
import Five from "./animations/five"
import Six from "./animations/six"

import WhyItems from './whyItem'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

    padding: 0 10px;

    h2 {
        color: ${colors.tertiary};
        font-size: 48px;
        font-family: ${fonts.poppins};
        font-weight: bold;
        line-height: 54px;
        margin: 0 0 60px;

        @media ${device.tablet}{
        margin-top: 30px;
        }

        @media ${device.phone}{
        font-size: 40px;
        line-height: 45px;
        margin: 0 auto 40px 0;
        }
    }

    > p {
        color: ${colors.tertiary};
        font-size: 16px;
        font-family: ${fonts.poppins};
        font-weight: 300;
        line-height: 29px;
        margin: 0 auto 100px
    }
`

const WhysContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    
    margin: 0 -10px;
`

const WhyItem = styled.div`
    width: 33.3333%;
    padding: 0 10px;
    box-sizing: border-box;

    &:nth-child(1n + 4){
        margin-top: 100px;
    }

    @media ${device.tablet}{
        width: 50%;
        
        &:nth-child(1n + 3){
            margin-top: 80px;
        }
    }

    @media ${device.phone}{
        width: 100%;
        
        &:nth-child(1n + 2){
            margin-top: 40px;
        }
    }
`

const IconContainer = styled.div`
  margin: 0 auto 40px;
  max-height: 100px;
`

const WhyTitle = styled.h3`
color: ${colors.tertiary};
    font-size: 23px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 26px;
`

const WhyDescription = styled.div`
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: 300;
    line-height: 29px;

    p {
        margin: 20px 0 0;
    }
`


const BinariosWhys = ({data, whys: {title, description}}) => {

  const [showTitle, setShowTitle] = useState(false)
  const [showText, setShowText] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)


  useEffect( () => {
    const setWidth = () => setWindowWidth(window.innerWidth);
    
    if(typeof window !== 'undefined') {
      setWidth()
      window.addEventListener("resize", () => setWidth)
    }

    return () => window.removeEventListener("resize", () => setWidth)
    
  }, [])

  useEffect(() => {
    windowWidth < deviceSize.tablet ? setIsMobile(true) : setIsMobile(false)
  }, [windowWidth])


  //spring animations:
  const [ref, titleEntry] = useIntersect({ threshold: 1 });
  const [refText, textEntry] = useIntersect({ threshold: 0.5 });

  const length = data.length;

  const titleProps = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: showTitle ? 1 : 0,
    },
    onRest: !showTitle && titleEntry.isIntersecting ? setShowTitle(true) : null
  });

  const textProps = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: showText ? 1 : 0,
    },
    onRest: !showText && textEntry.isIntersecting ? setShowText(true) : null
  });

  const trail = useTrail(length, {
    opacity: showText ? 1 : 0,
    transform: showText ? "translateY(0px)" : "translateY(200px)",
    from: {
      opacity: 0, 
      transform: "translateY(200px)"
    }
  });

  return (

    <Container>

      <animated.h2 ref={ref} style={titleProps}>{title}</animated.h2>
      <animated.p ref={refText} style={textProps} dangerouslySetInnerHTML={{__html: description}} />


      <WhysContainer>
      
        { !isMobile ? trail.map((props, index) => index < length && (

          <WhyItem key={"Why-" + index}>
            <animated.div style={props}>
            {showText &&
              <IconContainer>
                
                {data[index].node.acf.icon_selector === "one" && <One/>}
                {data[index].node.acf.icon_selector === "two" && <Two/>}
                {data[index].node.acf.icon_selector === "three" && <Three/>}
                {data[index].node.acf.icon_selector === "four" && <Four/>}
                {data[index].node.acf.icon_selector === "five" && <Five/>}
                {data[index].node.acf.icon_selector === "six" && <Six/>}

              </IconContainer>  }
              <WhyTitle>{data[index].node.title}</WhyTitle>
              <WhyDescription dangerouslySetInnerHTML={{__html: data[index].node.content}}/>
            </animated.div>
          </WhyItem>

          )) :
          data.map(({node}, index) => (

            <WhyItems node={node} key={"why-" + index}/>
            
          ))
        }
  
      </WhysContainer>
        
    </Container>
  )
} 

export default BinariosWhys
