import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const Four = () => {

    const threeRectangle = useRef()
    const threeCircle = useRef()
    const threeRectangle2 = useRef()
    const threeShadowRight = useRef()
    const threeShadowLeft = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
      tl.to(threeRectangle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"})
      .to(threeCircle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeRectangle2.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeShadowLeft.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeShadowRight.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
      .to(threeRectangle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut})
      .to(threeCircle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
      .to(threeRectangle2.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
      .to(threeShadowRight.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
      .to(threeShadowLeft.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6");
    }, [] )

    return (
        <div>
            <svg width="80px" height="95px">
                <g stroke="none" strokeWidth="1" fill="none">
                    <g transform="translate(-579.000000, -179.000000)">
                        <g transform="translate(579.000000, 179.000000)">
                            <path ref={threeRectangle} d="M10.4936799,38.2718545 L31.1613213,17.725251 C36.9422307,11.9594914 41.6637937,7.21657155 41.6063783,7.15915615 C33.0494552,-1.397767 19.1478836,-1.36970901 10.5563492,7.22182541 C1.96481477,15.8133598 1.93675678,29.7149314 10.4936799,38.2718545 Z" stroke="#FC4C5D" strokeWidth="2" strokeLinejoin="round"></path>
                            <path ref={threeCircle} d="M53.8345238,30 C53.8345238,28.8954305 52.9390933,28 51.8345238,28 L5.83452378,28 C4.72995428,28 3.83452378,28.8954305 3.83452378,30 L3.83452378,38 C3.83452378,39.1045695 4.72995428,40 5.83452378,40 L51.8345238,40 C52.9390933,40 53.8345238,39.1045695 53.8345238,38 L53.8345238,30 Z"  stroke="#2214FE" strokeWidth="2" strokeLinejoin="round"></path>
                            <path  ref={threeRectangle2} d="M40.8345238,66 C41.9390933,66 42.8345238,65.1045695 42.8345238,64 L42.8345238,3 C42.8345238,1.8954305 41.9390933,1 40.8345238,1 L32.8345238,1 C31.7299543,1 30.8345238,1.8954305 30.8345238,3 L30.8345238,64 C30.8345238,65.1045695 31.7299543,66 32.8345238,66 L40.8345238,66 Z" stroke="#2214FE" strokeWidth="2"></path>
                            <ellipse ref={threeShadowLeft} fill="#FFEBE6" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                            <ellipse ref={threeShadowRight} fill="#FFEBE6" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Four