import React from 'react'
import styled from 'styled-components'
import device from '../../styles/device'
import colors from '../../styles/colors'
import BinariosHeroTitle from './binariosHeroTitle'
import VostokConnect from '../vostokConnect'
import CtaScrollDown from '../ctaScrollDown'



const BinariosHeroS = styled.div`
  display: flex;
  padding: 180px 8.3333% 80px;
  height: 100vh;
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(270deg, ${colors.primary}, ${colors.secondary});
    background-size: 200% 100%;
    animation: AnimationName 10s ease infinite;
    z-index: 0;
  }

  @keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }

  @media ${device.phone}{
      padding: 20px;
  }
`

const BinariosHeroContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  max-width: 1440px;
  height: 100%;

  

  @media ${device.tablet}{
    flex-direction: column;
  }
`

const BinariosHero = ({heroTitle}) => (
  <BinariosHeroS>
    <BinariosHeroContainer>
      <VostokConnect/>
      <BinariosHeroTitle title={heroTitle}/>
      <CtaScrollDown target="#binariosContent"/>
    </BinariosHeroContainer>
  </BinariosHeroS>
);

export default BinariosHero;