import React, {useState} from 'react'
import styled from "styled-components"
// import Img from "gatsby-image"

//styles:
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device from "../../styles/device"

//animations:
import { useSpring, animated } from 'react-spring'
import useIntersect from '../../utils/useIntersect'

//icons:
import One from "./animations/one"
import Two from "./animations/two"
import Three from "./animations/three"
import Four from "./animations/four"
import Five from "./animations/five"
import Six from "./animations/six"


const WhyItem = styled.div`
    width: 33.3333%;
    padding: 0 10px;
    box-sizing: border-box;

    &:nth-child(1n + 4){
        margin-top: 100px;
    }

    @media ${device.tablet}{
        width: 50%;
        
        &:nth-child(1n + 3){
            margin-top: 80px;
        }
    }

    @media ${device.phone}{
        width: 100%;
        
        &:nth-child(1n + 2){
            margin-top: 95px;
        }
    }
`

const IconContainer = styled.div`
  margin: 0 auto 23px;
  max-height: 100px;
`

const WhyTitle = styled.div`
    color: ${colors.tertiary};
    font-size: 23px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 26px;
`

const WhyDescription = styled.div`
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: 300;
    line-height: 29px;

    p {
        margin: 20px 0 0;
    }
`

const WhyItems = ({node}) => {

  const [show, setShow] = useState(false)

  //spring animations:
  const [ref, entry] = useIntersect({ threshold: 0.3 });

  const itemProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(200px)`
    },
    to: {
      transform: show ? `translateY(0px)` : `translateY(200px)`,
      opacity: show ? 1 : 0,
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  });

  return (

    <WhyItem ref={ref}>
      <animated.div style={itemProps}>
       
       { show &&
        <IconContainer>
            {node.acf.icon_selector === "one" && <One/>}
            {node.acf.icon_selector === "two" && <Two/>}
            {node.acf.icon_selector === "three" && <Three/>}
            {node.acf.icon_selector === "four" && <Four/>}
            {node.acf.icon_selector === "five" && <Five/>}
            {node.acf.icon_selector === "six" && <Six/>}
        </IconContainer> }

        <WhyTitle>{node.title}</WhyTitle>
        <WhyDescription dangerouslySetInnerHTML={{__html: node.content}}/>

      </animated.div>
    </WhyItem>

  )
} 

export default WhyItems
