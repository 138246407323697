import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const Five = () => {

    const threeRectangle = useRef()
    const threeCircle = useRef()
    const threeRectangle2 = useRef()
    const threeShadowRight = useRef()
    const threeShadowLeft = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
      tl.to(threeRectangle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"})
      .to(threeCircle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeRectangle2.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeShadowLeft.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeShadowRight.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
      .to(threeRectangle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut})
      .to(threeCircle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
      .to(threeRectangle2.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
      .to(threeShadowRight.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
      .to(threeShadowLeft.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6");
    }, [] )

    return (
        <div>
            <svg width="80px" height="95px">
                <g stroke="none" strokeWidth="1" fill="none">
                    <g transform="translate(-579.000000, -179.000000)">
                        <g transform="translate(579.000000, 179.000000)">
                            <path ref={threeRectangle} d="M26.0886277,22.5 L26.0039153,51.012 C25.9915975,59.46 26.0053481,66.5 26.0886277,66.5 C38.1899445,66.5 48,56.6502645 48,44.5 C48,32.3497355 38.1899445,22.5 26.0886277,22.5 Z" stroke="#FC4C5D" strokeWidth="2" strokeLinejoin="round"></path>
                            <path ref={threeCircle} d="M19.9274864,36.5 L19.9960013,13.692488 C20.0074018,6.5516 19.9973277,0.5 19.9274864,0.5 C10.0264091,0.5 2,8.5588745 2,18.5 C2,28.4411255 10.0264091,36.5 19.9274864,36.5 Z"  stroke="#2214FE" strokeWidth="2" strokeLinejoin="round"></path>
                            <path  ref={threeRectangle2} d="M44.5,3 C44.5,1.8954305 43.6045695,1 42.5,1 L12.5,1 C11.3954305,1 10.5,1.8954305 10.5,3 L10.5,15 C10.5,16.1045695 11.3954305,17 12.5,17 L42.5,17 C43.6045695,17 44.5,16.1045695 44.5,15 L44.5,3 Z" stroke="#2214FE" strokeWidth="2"></path>
                            <ellipse ref={threeShadowLeft} fill="#FFEBE6" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                            <ellipse ref={threeShadowRight} fill="#FFEBE6" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Five