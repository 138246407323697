import React from 'react'
import Particles from 'react-particles-js'
import styled from 'styled-components'
import colors from '../../styles/colors'

const VostokConnectS = styled.div`
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: linear-gradient(to bottom, ${colors.primary} 0%, ${colors.primary} 5%, ${colors.secondary} 50%, ${colors.primary} 95%, ${colors.primary} 100%);
  background-size: 100% 2000%;
  animation: AnimationSky 15s ease infinite;

  @keyframes AnimationSky { 
    0%{background-position:0% 100%}
    100%{background-position:0% 0%}
  }
`

const VostokStarsConnect = () => {

  const ww = typeof window !== 'undefined' ? window.innerWidth : 100

  return(
    <VostokConnectS>
      <Particles
        className="vostokConnectContainer"
        params={{
          "particles": {
            "number": {
              "value": ww < 480 ? 10 : 10,
              "density": {
                "enable": false,
                "value_area": 0
              }
            },
            "color": {
              "value": "#ffffff"
            },
            "opacity": {
              "value": 0.5,
              "random": false,
            },
            "size": {
              "value": 2,
              "random": false,
            },
            "line_linked": {
              "enable": true,
              "distance": 200,
              "color": "#ffffff",
              "opacity": 0.3,
              "width": 1
            },
            "move": {
              "enable": true,
              "speed": 2,
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "bounce": false,
              "attract": {
                "enable": false,
              }
            }
          },
          "interactivity": {
            "detect_on": "canvas",
            "events": {
              "onhover": {
                "enable": false,
                "mode": "repulse"
              },
              "onclick": {
                "enable": false,
                "mode": "push"
              },
              "resize": true
            },
            "modes": {
              "grab": {
                "distance": 400,
                "line_linked": {
                  "opacity": 1
                }
              },
              "bubble": {
                "distance": 400,
                "size": 40,
                "duration": 2,
                "opacity": 8,
                "speed": 3
              },
              "repulse": {
                "distance": 200,
                "duration": 0.4
              },
              "push": {
                "particles_nb": 4
              },
              "remove": {
                "particles_nb": 2
              }
            }
          },
          "retina_detect": true
          }} />
    </VostokConnectS>
  )
}

export default VostokStarsConnect