import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {Helmet} from "react-helmet";

import Layout from "../layout"

import device from "../styles/device"

import BinariosHero from "../components/binariosHero"
import BinariosWhys from "../components/whys"
import BinariosMembers from "../components/members"
import Clients from '../components/clients'
import ContactCta from "../components/contactCta"

//data:
import langData from '../data/languages/ca';


const BinariosContainer = styled.div`
  max-width: 1440px;
  
  margin: auto;
  padding: 100px 8.3333333%;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const BinariosRow = styled.div`    
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
`


const Binarios = ({data}) => {

  const {sideMenu, cookies, footer, binarios: {heroTitle, helmet: {title, meta}, whys, teamTitle, clients}, contactBanner: {normal}} = langData;
  
  return (
    <Layout negative="light" sideMenu={sideMenu} cookies={cookies} footer={footer}>

      <Helmet>
          <title>{title}</title>
          <meta name="description" content={meta} />
      </Helmet>


    <BinariosHero heroTitle={heroTitle}/>

    <BinariosContainer id="binariosContent">
      <BinariosRow>
    
        <BinariosWhys whys={whys} data={data.allWordpressWpWhy.edges}/>

        <BinariosMembers teamTitle={teamTitle} data={data.allWordpressWpMember.edges} />
          
      </BinariosRow>
    </BinariosContainer>

    <Clients data={data.allWordpressWpClient} negative={true} clients={clients}/>

    <ContactCta normal={normal}/>

  </Layout>

  )
}

export default Binarios

export const pageQuery = graphql`
query {

  allWordpressWpWhy(filter: {wordpress_site: {eq: "ca"}}, sort: { fields: [date], order: ASC }) {
    edges {
      node{
        title
        content
        acf {
          icon_selector
        }
      }
    }
  }

  allWordpressWpMember(sort: { fields: [menu_order], order: ASC }) {
    edges {
      node {
        title
        acf {
          description
          image_for_hover {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 72) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 72) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }

  allWordpressWpClient(sort: { fields: [date], order: DESC }) {
    edges {
      node {
        id
        title
        featured_media{
          localFile{
            childImageSharp{
              fixed{
                src
              }
            }
          }
        }
      }
    }
  }
  
}
`
