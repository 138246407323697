import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const Three = () => {

    const threeRectangle = useRef()
    const threeCircle = useRef()
    const threeRectangle2 = useRef()
    const threeShadowRight = useRef()
    const threeShadowLeft = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
      tl.to(threeRectangle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"})
      .to(threeCircle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeRectangle2.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeShadowLeft.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeShadowRight.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
      .to(threeRectangle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut})
      .to(threeCircle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
      .to(threeRectangle2.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
      .to(threeShadowRight.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
      .to(threeShadowLeft.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6");
    }, [] )

    return (
        <div>
            <svg width="80px" height="95px">
                <g stroke="none" strokeWidth="1" fill="none">
                    <g transform="translate(-579.000000, -179.000000)">
                        <g transform="translate(579.000000, 179.000000)">
                            <path ref={threeRectangle} d="M26.0886277,22.5 L26.0039153,51.012 C25.9915975,59.46 26.0053481,66.5 26.0886277,66.5 C38.1899445,66.5 48,56.6502645 48,44.5 C48,32.3497355 38.1899445,22.5 26.0886277,22.5 Z" stroke="#FC4C5D" strokeWidth="2" strokeLinejoin="round"></path>
                            <path ref={threeCircle} d="M47.5,3 C47.5,1.8954305 46.6045695,1 45.5,1 L9.5,1 C8.3954305,1 7.5,1.8954305 7.5,3 L7.5,15 C7.5,16.1045695 8.3954305,17 9.5,17 L45.5,17 C46.6045695,17 47.5,16.1045695 47.5,15 L47.5,3 Z"  stroke="#2214FE" strokeWidth="2" strokeLinejoin="round"></path>
                            <path  ref={threeRectangle2} d="M29.5,56.5 C29.5,55.3954305 28.6045695,54.5 27.5,54.5 L9.5,54.5 C8.3954305,54.5 7.5,55.3954305 7.5,56.5 L7.5,64.5 C7.5,65.6045695 8.3954305,66.5 9.5,66.5 L27.5,66.5 C28.6045695,66.5 29.5,65.6045695 29.5,64.5 L29.5,56.5 Z" stroke="#2214FE" strokeWidth="2"></path>
                            <ellipse ref={threeShadowLeft} fill="#E4E1FF" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                            <ellipse ref={threeShadowRight} fill="#E4E1FF" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Three