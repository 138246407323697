export default {

  sideMenu: {
    primary: [
      {
        name: "Projectes",
        slug: "/ca/projectes/"
      },
      {
        name: "Serveis",
        slug: "/ca/serveis/"
      },
      {
        name: "Binaris",
        slug: "/ca/binaris/"
      },
      {
        name: "Contacte",
        slug: "/ca/contacte/"
      },
      {
        name: "Blog",
        slug: "/ca/blog/"
      }
    ],
    home: "/ca/",
    languages: ["Español", "Català", "English"]
  },

  home: {
    helmet: {
      title: "Desenvolupament i Disseny Web | Binaria, agència digital",
      meta: "Binaria és una agència digital de Barcelona amb 20 anys de trajectòria. Fem disseny UI/UX, lloc web, progressive web apps i altres productes digitals."
    },
    heroText: "Producte digital connectant tècnica i creativitat",
    intro: "Som una agència digital amb seu a Barcelona. Unim la nostra passió pel disseny centrat en les persones amb tecnologies avançades de desenvolupament.",
    projects: {
      title: "El nostre treball",
      description: "Descobreix part dels nostres projectes",
      link: "Tots els projectes"
    },
    servicios: {
      title: "Què fem",
      link: "Serveis"
    },
    clients: {
      title: "Els nostres clients",
      link: "Coneix-nos"
    },
    contactHome: {
      title: "Escriu-nos",
      description: "Estarem encantats de sentir el que vulguis explicar-nos i oferir-te les solucions que necessita la teva empresa."
    }
  },

  projects: {
    helmet: {
      title: "Projectes | Binaria, agència digital",
      meta: "Et mostrem alguns dels projectes en els que hem acompanyat als nostres clients. Coneix exemples de les iniciatives digitals amb les que treballem."
    },
    title: "El nostre treball",
    description: "Descobreix els nostres projectes, experiències digitals fàcils d'usar, àgils i fiables",
    navProjects: {
      left: "Anterior",
      center: "Veure tots",
      right: "Següent"
    }
  },

  services: {
    helmet: {
      title: "Serveis | Binaria, agència digital",
      meta: "Descobreix tots els serveis de Binaria. Ens especialitzem en desenvolupament Web i disseny UX/UI, entre altres."
    },
    heroTitle: "Deixa'ns les claus de la teva nau, nosaltres pilotem",
    servicesListTitle: "Què fem",
    sliderTitle: "Com ho fem",
    carousel: {
      title: "Per què ens apassiona?",
      subTitle: "Perquè creiem que un bon producte...",
      sentences: {
        allSentencesWithHighlights: [
          "...és <span class='primary'>obert</span> i <span class='secondary'>col·laboratiu</span>",
          "...requereix <span class='primary'>temps</span> i <span class='secondary'>constància</span>",
          "...es treballa cada dia <span class='primary'>aprenent</span> i <span class='secondary'>investigant</span>",
          "...surt de ments <span class='primary'>inquietes</span> i <span class='secondary'>humils</span>",
          "...és <span class='primary'>innovador</span> i <span class='secondary'>carismàtic</span>",
          "...satisfà les <span class='primary'>necessitats</span> i <span class='secondary'>expectatives</span> de l'usuari",
          "...en definitiva, és la <span class='primary'>sinergia</span> entre dues <span class='secondary'>qualitats</span>",
        ],
        allSentences: [
          "...és obert i col·laboratiu",
          "...requereix temps i constància",
          "...es treballa cada dia aprenent i investigant",
          "...surt de ments inquietes i humils",
          "...és innovador i carismàtic",
          "...satisfà les necessitats i expectatives de l'usuari",
          "...en definitiva, és la sinergia entre dues qualitats",
        ]
      }
    }
  },

  binarios: {
    helmet: {
      title: "Nosaltres | Binaria, agència digital",
      meta: "T'expliquem com i per què fem el que ens apassiona. Nosaltres som l'equip de Binaria."
    },
    heroTitle: "Som dues forces que creen una sol·lució",
    whys: {
      title: "Per què Binaria",
      description: "Som un equip bolcat en cada projecte d'inici a fi. Unim <strong>creativitat</strong> i <strong>tècnica</strong> per a crear experiències digitals amb un disseny de qualitat i un desenvolupament òptim i eficient. Especialitzats en usabilitat, arquitectura de la informació i programació avançada, usem metodologies àgils per a assegurar la qualitat dels projectes."
    },
    teamTitle: "Equip",
    clients: {
      title: "Els nostres clients"
    },
  },

  contact: {
    helmet: {
      title: "Contacte | Binaria, agència digital",
      meta: "Contacta amb Binaria, estem encantats de conèixer-te i poder col y poder col·laborar amb tu! Completa el formulari i ens comunicarem a la brevetat"
    },
    title: "Dispara ",
    description: "Som una agència digital especialitzada en disseny de producte i desenvolupament avançat. Estem encantats de sentir el que vulguis explicar-nos i oferir-te les solucions que necessita la teva empresa.",
    phone: "Telèfon",
    email: "Email",
    address: "Adreça",
    address1: "Av Josep Tarradellas, 123, 6è pis",
    address2: "08029 Barcelona",
    address3: "Aticco",
    viewMap: "Veure mapa",
    placeholders: {
      name: "Nom i cognoms",
      email: "Email",
      message: "Missatge",
      accept: "Acceptació"
    },
    conditions: "Accepto rebre emails de Binaria i la seva",
    policyLink: "política de privacitat",
    policyPath: "/ca/politica-de-privacitat/",
    button: "Enviar",
    loadingMsg: "Enviant...",
    errorMsg: "La teva informació no ha pogut ser enviada. Si us plau, intenta-ho de nou.",
    successMsg: "Hem rebut el teu missatge, ¡gràcies!"
  },

  blog: {
    helmet: {
      title: "Blog | Binaria, agència digital",
      meta: "Troba les últimes novetats al món digital. Des de l'equip de Binaria, t'expliquem el que cal saber per a estar al dia amb les tendències."
    },
    title: "Blog ",
    subTitle: "Descobreix les nostres reflexions sobre el disseny, UX/UI i desenvolupament"
  },

  post: {
    otherPostsTitle: "Altres articles que poden interessar-te:"
  },

  contactBanner: {
    normal: {
      title: "Explica'ns el teu projecte",
      button: "Contacta",
      link: "/ca/contacte"
    },
    postBanner: {
      title: "¿En vols saber més?",
      button: "Escriu-nos",
      link: "/ca/contacte"
    }
  },

  footer: {
    links: [
      {
        name: "Projectes",
        slug: "/ca/projectes/"
      },
      {
        name: "Serveis",
        slug: "/ca/serveis/"
      },
      {
        name: "Binaris",
        slug: "/ca/binaris/"
      },
      {
        name: "Contacte",
        slug: "/ca/contacte/"
      },
      {
        name: "Blog",
        slug: "/ca/blog/"
      },
      {
        name: "Jobs",
        slug: "/ca/jobs/"
      },
    ],
    legal: [
      "© 2019 Binaria Web Services S.L. Reservats tots els drets.",
      {
        name: "Avís legal",
        path: "/ca/avis-legal/"
      },
      " i ",
      {
        name: "política de privacitat",
        path: "/ca/politica-de-privacitat/"
      }
    ]
  },

  cookies: {
    description: "Aquesta web fa servir cookies per a millorar la seva experiència. En navegar en ella, entenem que està d'acord amb la nostra ",
    link: "política de privacitat",
    acceptButton: "Acceptar",
    rejectButton: "Rebutjar",
    path: "/ca/politica-de-privacitat/"
  },

  errorPage: {
    metaTitle: "Error 404, Catalan not found | Binaria, agencia digital",
    metaDesc: "Te contamos cómo y por qué hacemos lo que nos apasiona. Nosotros somos el equipo de Binaria.",
    title: "Error 404",
    text: "¡Lo sentimos! ¡La página que estás buscando no existe!",
    button: "Volver al inicio",
    link: "/ca/"
  }

}