import React, {useState} from 'react'
import styled from "styled-components"
import Img from "gatsby-image"

//styles:
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device from "../../styles/device"

//animations:
import { useSpring, animated } from 'react-spring'
import useIntersect from '../../utils/useIntersect'

const MemberContainer = styled.div`
    width: 25%;
    padding: 0 10px;
    box-sizing: border-box;
    height: fit-content;

    &:nth-child(1n + 5){
        margin-top: 100px;
    }

    @media ${device.tablet}{
        width: 50%;
        
        &:nth-child(1n + 3){
            margin-top: 80px;
        }
    }

    @media ${device.phone}{
        width: 100%;
        
        &:nth-child(1n + 2){
            margin-top: 40px;
        }
    }

    .gatsby-image-wrapper, img {
        user-select: none;
        user-drag: none;
    }
`

const ImageContainer = styled.div`
    margin-bottom: 20px;
    width: 100%;
    display: block;

    :hover {
      .gatsby-image-wrapper:first-child {
        visibility: hidden;
      }
      .gatsby-image-wrapper:last-child {
        visibility: visible;
      }
    }

    @media ${device.phone}{
      margin-bottom: 20px;
    }
    
`

const MemberImageDark = styled(Img)`
    width: inherit;
    height: inherit;
`

const MemberImage = styled(Img)`
    margin-top: -123.27%;
    visibility: hidden;
    width: inherit;
    height: inherit;
`

const MemberTitle = styled.div`
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 29px;
`

const MemberDescription = styled.div`
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: 300;
    line-height: 29px;
`



const Member = ({node, index}) => {

  const [show, setShow] = useState(false)

  const thrs = 0.3

  const [ref, entry] = useIntersect({
    threshold: thrs
  });

  const itemProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(200px)`
    },
    to: {
      transform: show ? `translateY(0px)` : `translateY(200px)`,
      opacity: show ? 1 : 0,
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  });

  return (

    <MemberContainer key={"Member-" + index} ref={ref}>
      <animated.div style={itemProps}>
        <ImageContainer>
          <MemberImageDark fluid={node.featured_media.localFile.childImageSharp.fluid}/>
          <MemberImage fluid={node.acf.image_for_hover.localFile.childImageSharp.fluid}/>
        </ImageContainer>

        <MemberTitle>{node.title}</MemberTitle>
        <MemberDescription dangerouslySetInnerHTML={{__html: node.acf.description}}/>
      </animated.div>
  </MemberContainer>
          
  )
} 

export default Member
