import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const Two = () => {

    const twoCircle = useRef()
    const twoCircle2 = useRef()
    const twoRectangle = useRef()
    const twoShadowRight = useRef()
    const twoShadowLeft = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
      tl.to(twoCircle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"})
      .to(twoCircle2.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
      .to(twoRectangle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
      .to(twoShadowLeft.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(twoShadowRight.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
      .to(twoCircle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut})
      .to(twoCircle2.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
      .to(twoRectangle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
      .to(twoShadowRight.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
      .to(twoShadowLeft.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6");
    }, [] )

    return (
        <div>
            <svg width="80px" height="95px">
                <g stroke="none" strokeWidth="1" fill="none">
                    <g transform="translate(-347.000000, -178.000000)">
                        <g transform="translate(347.000000, 179.000000)">
                            <path ref={twoCircle} d="M4.79268293,22.4113723 L32.6566829,22.4960847 C40.9126829,22.5084025 47.7926829,22.4946519 47.7926829,22.4113723 C47.7926829,10.3100555 38.166805,0.5 26.2926829,0.5 C14.4185608,0.5 4.79268293,10.3100555 4.79268293,22.4113723 Z" stroke="#FC4C5D" strokeWidth="2" strokeLinejoin="round"></path>
                            <path ref={twoCircle2} d="M34.3974248,0 L34.2939185,35.930112 C34.2868245,44.3904 34.3088455,51 34.3974248,51 C48.6989809,51 60.2926829,39.5832611 60.2926829,25.5 C60.2926829,11.4167389 48.6989809,0 34.3974248,0 Z"  stroke="#FC4C5D" strokeWidth="2" strokeLinejoin="round"></path>
                            <path  ref={twoRectangle} d="M8.79268293,70.5 C8.79268293,71.6045695 9.68811343,72.5 10.7926829,72.5 L57.7926829,72.5 C58.8972524,72.5 59.7926829,71.6045695 59.7926829,70.5 L59.7926829,56.5 C59.7926829,55.3954305 58.8972524,54.5 57.7926829,54.5 L10.7926829,54.5 C9.68811343,54.5 8.79268293,55.3954305 8.79268293,56.5 L8.79268293,70.5 Z" stroke="#2214FE" strokeWidth="2"></path>
                            <ellipse ref={twoShadowRight} fill="#E4E1FF" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                            <ellipse ref={twoShadowLeft} fill="#E4E1FF" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Two