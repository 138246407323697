import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const Six = () => {

    const sixRectangle = useRef()
    const sixCircle = useRef()
    const threeShadowRight = useRef()
    const threeShadowLeft = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
      tl.to(sixRectangle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"})
      .to(sixCircle.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeShadowLeft.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"left"}, 0)
      .to(threeShadowRight.current, 0.3, {scaleX:0.25, scaleY: 0.75, transformOrigin:"right"}, 0)
      .to(sixRectangle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut})
      .to(sixCircle.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
      .to(threeShadowRight.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
      .to(threeShadowLeft.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6");
    }, [] )

    return (
        <div>
            <svg width="80px" height="95px">
                <g stroke="none" strokeWidth="1" fill="none">
                    <g transform="translate(-579.000000, -179.000000)">
                        <g transform="translate(579.000000, 179.000000)">
                            <path ref={sixRectangle} d="M40.0813703,2.90811691 C39.3003217,2.12706832 38.0339918,2.12706832 37.2529432,2.90811691 L10.3828855,29.7781746 C9.60183691,30.5592232 9.60183691,31.8255531 10.3828855,32.6066017 L18.8681669,41.0918831 C19.6492155,41.8729317 20.9155454,41.8729317 21.696594,41.0918831 L48.5666517,14.2218254 C49.3477003,13.4407768 49.3477003,12.1744469 48.5666517,11.3933983 L40.0813703,2.90811691 Z" stroke="#2214FE" strokeWidth="2" strokeLinejoin="round"></path>
                            <circle ref={sixCircle} cx="31.1463415" cy="45.3284271" r="26" stroke="#FC4C5D" strokeWidth="2" strokeLinejoin="round"></circle>
                            <ellipse ref={threeShadowLeft} fill="#FFEBE6" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                            <ellipse ref={threeShadowRight} fill="#FFEBE6" cx="32" cy="88.5" rx="32" ry="2.5"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Six