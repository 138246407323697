import React, {useState, useEffect} from 'react'
import styled from "styled-components"
import Img from "gatsby-image"

import Member from './member'

//styles:
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device, {deviceSize} from "../../styles/device"

//animations:
import { useTrail, useSpring, animated } from 'react-spring'
import useIntersect from '../../utils/useIntersect'





const BinariosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  padding: 0 10px;

  margin-top: 100px;

  @media ${device.tablet}{
    margin-top: 140px;
  }

  @media ${device.phone}{
    margin-top: 100px;
  }

  h2 {
    color: ${colors.tertiary};
    font-size: 48px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 54px;
    margin: 0 auto 45px 0;

    @media ${device.tablet}{
      margin-top: 30px;
    }

    @media ${device.phone}{
      font-size: 40px;
      line-height: 45px;
      margin: 10px auto 40px 0;
    }
  }
`

const BinariosMembersList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  
  margin: 0 -10px;
`

const MemberContainer = styled.div`
    width: 25%;
    padding: 0 10px;
    box-sizing: border-box;
    height: fit-content;

    &:nth-child(1n + 5){
        margin-top: 100px;
    }

    @media ${device.tablet}{
        width: 50%;
        
        &:nth-child(1n + 3){
            margin-top: 80px;
        }
    }

    @media ${device.phone}{
        width: 100%;
        
        &:nth-child(1n + 2){
            margin-top: 40px;
        }
    }

    .gatsby-image-wrapper, img {
        user-select: none;
        user-drag: none;
    }
`

const ImageContainer = styled.div`
    margin-bottom: 20px;
    width: 100%;
    display: block;

    :hover {
      .gatsby-image-wrapper:first-child {
        visibility: hidden;
      }
      .gatsby-image-wrapper:last-child {
        visibility: visible;
      }
    }    
`

const MemberImageDark = styled(Img)`
    width: inherit;
    height: inherit;
`

const MemberImage = styled(Img)`
    margin-top: -123.27%;
    visibility: hidden;
    width: inherit;
    height: inherit;
`

const MemberTitle = styled.h3`
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 29px;
    margin: 0;
`

const MemberDescription = styled.div`
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: 300;
    line-height: 29px;
`

const BinariosMembers = ({data, teamTitle}) => {

  const [show, setShow] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)


  useEffect( () => {
    if(typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
      window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
      })
    }
  }, [])

  useEffect(() => {
    windowWidth < deviceSize.tablet ? setIsMobile(true) : setIsMobile(false)
  }, [windowWidth])


  const thrs = isMobile ? 0.3 : 0

  const [ref, entry] = useIntersect({
    threshold: thrs
  });

  const length = data.length;

  const titleProps = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: show ? 1 : 0,
    },
  onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  });

  const trail = useTrail(length, {
    opacity: show ? 1 : 0,
    transform: show ? `translateY(0px)` : `translateY(200px)`,    
    from: {
      opacity: 0, 
      transform: `translateY(200px)`
    }
  });


  return (

    <BinariosContainer ref={ref}>

      <animated.h2 style={titleProps}>{teamTitle}</animated.h2>

      <BinariosMembersList >

          { !isMobile ? trail.map((props, index) => index < length && (
              <MemberContainer key={"Member-" + index}>
                  <animated.div style={props}>
                      <ImageContainer>
                          <MemberImageDark fluid={data[index].node.featured_media.localFile.childImageSharp.fluid}/>
                          <MemberImage fluid={data[index].node.acf.image_for_hover.localFile.childImageSharp.fluid}/>
                      </ImageContainer>

                      <MemberTitle>{data[index].node.title}</MemberTitle>
                      <MemberDescription dangerouslySetInnerHTML={{__html: data[index].node.acf.description}}/>
                  </animated.div>
              </MemberContainer>
              )) :
            data.map(({node}, index) => (
              <Member node={node} index={index} key={"member-" + index}/>
            ))
          }
      </BinariosMembersList>
    
    </BinariosContainer>
  )
} 

export default BinariosMembers
