import React, {useState} from "react"
import styled from 'styled-components'
import Link from "gatsby-link"
import { useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import device from '../../styles/device'


const ContactCtaSection = styled.section`
  background: linear-gradient(-225deg, rgb(34, 20, 254) 0%, rgb(252, 76, 93) 100%);
`

const ContactCtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  
  margin: auto;
  padding: 68px 8.3333333%;

  @media ${device.tablet}{
    padding: 40px 30px;
  }

  @media ${device.phone}{
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 40px 20px;
  }
`

const ContactCtaTitle = styled.h2`
    color: white;
    font-size: 40px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 45px;
    margin-right: auto;

    @media ${device.phone}{
      margin-bottom: 40px;
    }
`

const ContactCtaButton = styled(Link)`
  background: white;
  padding: 10px 40px;
  margin: 31px 0;
  color: ${colors.tertiary};
  font-size: 16px;
  font-family: ${fonts.poppins};
  font-weight: bold;
  text-align: center;
  line-height: 29px;
  text-decoration: none;
  transition: opacity .2s;
  opacity: 1;

  &:hover {
    opacity: 0.8 !important;
  }

  @media ${device.phone}{
      margin: 0 0 33.2px;
  }
`

const ContactCta = ({normal: {title, button, link}}) => {

  const thrs = 0.1;

  const [ref, entry] = useIntersect({
    threshold: thrs
  });

  const [show, setShow] = useState(false);

  const propsLeft = useSpring({
      from: {
          opacity: 0, 
          transform: `translateX(-200px)`,
        },
        to: {
          opacity: show ? 1 : 0,
          transform: `translateX(${show ? 0 : -200}px)`
        },
        onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  })

  const propsRight = useSpring({
    from: {
        opacity: 0, 
        transform: `translateX(200px)`,
      },
      to: {
        opacity: show ? 1 : 0,
        transform: `translateX(${show ? 0 : 200}px)`
      },
      onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
});

  const AnimatedContactCtaTitle = animated(ContactCtaTitle);
  const AnimatedContactCtaButton = animated(ContactCtaButton);


  return (

    <ContactCtaSection>
      <ContactCtaContainer ref={ref}>

        <AnimatedContactCtaTitle style={propsLeft}>{title}</AnimatedContactCtaTitle>

        <AnimatedContactCtaButton style={propsRight} to={link}>{button}</AnimatedContactCtaButton>

      </ContactCtaContainer>    
    </ContactCtaSection>

  )
};

export default ContactCta;